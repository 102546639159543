import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import Breadcrumbs, { Breadcrumb } from "../components/Breadcrumbs/Breadcrumbs";
import Card from "../components/Card/Card";
import EventDetails from "../components/EventDetails/EventDetails";
import { Col, Row, Wrapper } from "../components/Grid";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import Text from "../components/Text/Text";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityPage } from "../model/common";
import { SanityEvent } from "../model/events";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import styles from "./EventPage.module.scss";

export const pageQuery = graphql`
  query EventPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    eventListingPage: sanityEventListingPage {
      _id
      _type
      title
    }
    page: sanityEvent(_id: { eq: $_id }) {
      ...SanityEvent
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
  }
`;

interface EventPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityEvent;
    eventListingPage: SanityPage;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

const EventPage = ({
  pageContext,
  data: { menus, page, eventListingPage },
  location
}: EventPageProps) => {
  const { title } = page;
  const breadcrumbs: Breadcrumb[] = [
    {
      title: eventListingPage.title,
      href: urlForDocument(eventListingPage)
    },
    {
      title: page.title,
      href: urlForDocument(page)
    }
  ];
  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.NEWS}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={location}
      strings={pageContext.strings}
    >
      <Wrapper className={styles.root}>
        <Row justify="center">
          <Col lg={8} md={10} sm={12}>
            <Row justify="between" style={{ marginBottom: "10px" }}>
              <Col md={8}>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </Col>
              <Col md={4}>
                <SocialLinks title={title} />
              </Col>
            </Row>
            <Card type="main">
              <Row>
                <Col md={12}>
                  <Text variant="h1">{title}</Text>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <EventDetails event={page} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Wrapper>
    </PageLayout>
  );
};
export default EventPage;
